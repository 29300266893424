<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode }">
    <diV>
<!--      <CNavbar expandable="md" type="dark" color="dark">-->
<!--        <CToggler in-navbar @click="collapsed = !collapsed"/>-->
<!--        <CNavbarBrand href="#"> <img src="@/assets/logo.png" height="40" class="ml-5"/> </CNavbarBrand>-->
<!--        <CCollapse :show="collapsed" navbar>-->
<!--          <CNavbarNav>-->
<!--            &lt;!&ndash;          <CNavItem href="#">Link</CNavItem>&ndash;&gt;-->
<!--            &lt;!&ndash;          <CNavItem href="#" disabled>Disabled</CNavItem>&ndash;&gt;-->
<!--          </CNavbarNav>-->

<!--          &lt;!&ndash; Right aligned nav items &ndash;&gt;-->
<!--          <CNavbarNav class="ml-auto">-->
<!--            <CForm inline>-->
<!--              <CInput-->
<!--                  class="mr-sm-2"-->
<!--                  placeholder="Search"-->
<!--                  size="sm"-->
<!--              />-->
<!--              <CButton-->
<!--                  color="secondary"-->
<!--                  size="sm"-->
<!--                  class="my-2 my-sm-0"-->
<!--                  type="submit"-->
<!--              >-->
<!--                Search-->
<!--              </CButton>-->
<!--            </CForm>-->

<!--            &lt;!&ndash;          <CDropdown&ndash;&gt;-->
<!--            &lt;!&ndash;              togglerText="Lang"&ndash;&gt;-->
<!--            &lt;!&ndash;              nav&ndash;&gt;-->
<!--            &lt;!&ndash;              placement="bottom-end"&ndash;&gt;-->
<!--            &lt;!&ndash;          >&ndash;&gt;-->
<!--            &lt;!&ndash;            <CDropdownItem>EN</CDropdownItem>&ndash;&gt;-->
<!--            &lt;!&ndash;            <CDropdownItem>ES</CDropdownItem>&ndash;&gt;-->
<!--            &lt;!&ndash;            <CDropdownItem>RU</CDropdownItem>&ndash;&gt;-->
<!--            &lt;!&ndash;            <CDropdownItem>FA</CDropdownItem>&ndash;&gt;-->
<!--            &lt;!&ndash;          </CDropdown>&ndash;&gt;-->


<!--          </CNavbarNav>-->
<!--        </CCollapse>-->
<!--      </CNavbar>-->
    </diV>
    <div class="m-auto col-10">
      <CRow>
        <CCol class="text-center m-auto">
          <img src="@/assets/logo/logo.png" width="100%"/>

          <CButton color="secondary" href="mailto:finnext.io@gmail.com" shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 40px; height: 40px" >
            <CIcon name="cil-envelope-closed" />
          </CButton>
          <CButton color="facebook" href="https://www.facebook.com/FinNext" shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 40px; height: 40px" >
            <CIcon name="cib-facebook-f" class="mr-1 ml-1"/>
          </CButton>
          <CButton color="twitter" href="https://t.me/joinchat/wXN4LlsTvrlkMWM1" shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 40px; height: 40px" >
            <CIcon name="cib-telegram-plane" class="mr-1"/>
          </CButton>
        </CCol>
        <CCol col="7" class="mt-5">
          <h3 class="font-weight-bold">Blockchain-based transactions which is more than a cryptocurrency wallet.</h3>
          <h4 class="mt-4">FINNext introduces user the adoption of DeFi in easier way with fast & cheap transactions include initiating the flexibility of Digital Currency Adoption in real life.</h4>
          <h4 class="mt-4">Open your mind to get in touch with taking advantage of FINNext.</h4>

          <div class="text-center">
            <CButton
                class="w-75 m-auto"
                color="info"
                size="lg"
                shape="pill"
            >
              Download Now
            </CButton>
          </div>
          <div class="text-center mt-4">
            <img class="mr-4" src="https://www.greenqueen.com.hk/wp-content/uploads/2020/02/Apple-App-Store-Icon.png" width="100px"/>
            <img src="https://w7.pngwing.com/pngs/52/715/png-transparent-google-play-logo-google-play-computer-icons-app-store-google-text-logo-sign.png" width="100px"/>
          </div>


        </CCol>
      </CRow>
      <CRow class="mt-5">
        <CCol class="mt-5">
          <h3 class="font-weight-bold">Buy, store, send and swap tokens</h3>
          <h4 class="mt-4">The features and/or functions of purely Decentralized Finance (DeFi) are not easy for mass users which including traditional traders, general investors, merchants and vendors.</h4>
          <h4 class="mt-4">FINNext introduces middle way of CeFi and DeFi with an innovative of flexibility of Digital Currency Adoption in real life.</h4>

          <div class="w-100 mt-4">
            <CRow>
              <CCol col="12" >
                <CWidgetIcon
                    header="Complexity of DeFi adoption"
                    text="DeFi is new generation of digital money market which difficult to understand and usable."
                    color="gradient-primary"
                    :icon-padding="false"
                >
                  <CIcon name="cil-institution" width="24"/>
                </CWidgetIcon>
              </CCol>
              <CCol col="12" >
                <CWidgetIcon
                    header="Limitation of multi-currency in wallet"
                    text="Unable to swap asset to other Low engagement of assets adoption blockchains."
                    color="gradient-primary"
                    :icon-padding="false"
                >
                  <CIcon name="cil-wallet" width="24"/>
                </CWidgetIcon>
              </CCol>
              <CCol col="12" >
                <CWidgetIcon
                    header="Difficult to use in real life."
                    text="Low engagement of assets adoption in real life."
                    color="gradient-primary"
                    :icon-padding="false"
                >
                  <CIcon name="cil-memory" width="24"/>
                </CWidgetIcon>
              </CCol>
            </CRow>
          </div>
        </CCol>
        <CCol class="text-center m-auto">
          <img src="https://metamask.io/images/wallet-illo.svg" width="70%"/>
        </CCol>
      </CRow>
      <CRow class="mb-5">
        <CCol class="text-center m-auto">
          <img src="https://metamask.io/images/Browse-illo.svg" width="100%"/>
        </CCol>
        <CCol class="mt-5">
          <h3 class="font-weight-bold">Mobile Application</h3>
          <h4>Get access to FINNext anywhere you go. Mobile application allows you to get advantage of DeFi in an easily way with built-in cross-chain and integrated merchant platform. Try by yourself.</h4>
        </CCol>
      </CRow>
    </div>
    <div>
      <CRow>
        <CCol>

        </CCol>
        <CCol class="text-right">
          <CButton color="facebook"  shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 40px; height: 40px" >
            <CIcon name="cib-facebook-f" class="mr-1 ml-1"/>
          </CButton>
          <CButton color="twitter"  shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 40px; height: 40px" >
            <CIcon name="cib-twitter" class="mr-1 ml-1"/>
          </CButton>
          <CButton color="yahoo"  shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 40px; height: 40px" >
            <CIcon name="cib-discord" class="mr-1 ml-1"/>
          </CButton>
<!--          Email  finnext.io@gmail.com-->
<!--          Facebook:  https://www.facebook.com/FinNext-->
<!--          Telegram :  https://t.me/joinchat/wXN4LlsTvrlkMWM1-->
        </CCol>
      </CRow>
      <CRow>
        <CCol></CCol>
        <CCol class="text-right">
          Contact Infomation:
          Powered by© 2021, NEXTART
        </CCol>
      </CRow>
    </div>


  </div>
</template>

<script>
export default {
  name: 'Splash',
  created() {
  },
  methods: {

  },
}

</script>
